.contacts {
	.contacts__container {
		max-width: 1280px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2%;
		margin-bottom: 100px;
	}
}

@media (max-width: 1300px) {
	.contacts {
		// height: 200vh;
		.contacts__container {
			max-width: 50%;
			margin: 0 auto;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(2, 1fr);
		}
	}
}

@media (max-width: 990px) {
	.contacts {
		.contacts__container {
			max-width: 80vw;
		}
	}
}

@media (max-width: 768px) {
	.contacts {
		.contacts__container {
			
		}
	}
}
