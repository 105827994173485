.btn {
	letter-spacing: 1px;
	display: block;
	width: 100%;
	margin: 0 auto;
	text-decoration: none;
	padding: 10px 0;
	background: #4da5c7;
	color: #fff;
	text-align: center;
	font-family: "1000";
	font-size: 16px;
	text-transform: uppercase;
	transition: background-color 0.5s ease;
	&:hover {
		background: #606060;
	}
}

@media (max-width: 768px) {
	.btn {
		font-size: 12px;
		&:hover {
		}
	}
}
