.footer {
	background: #ececec;
	margin-top: 30px;

	.footer__container {
		max-width: 1280px;
		margin: 0 auto;
		padding: 60px 0px;
		display: flex;
		justify-content: space-between;
	}
}

@media (max-width: 1300px) {
	.footer {
		.footer__container {
			max-width: 990px;
		}
	}
}

@media (max-width: 990px) {
	.footer {
		.footer__container {
			margin: 0;
			padding: 30px 20px;
		}
	}
}
