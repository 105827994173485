.registration {
	text-align: center;
	margin-bottom: 100px;
	.registration__content {
		max-width: 1280px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.registration__img {
		}
		.registration__info {
			font-family: "Mariupol";
			font-size: 16px;
			font-weight: 500;
			color: #808080;
			text-transform: uppercase;
			margin: 50px 0px;
			span {
				color: #d0d0d0;
			}
		}
		.registration__container {
			margin: 0 auto;
			display: flex;
			max-width: 50%;
			flex-direction: column;
			justify-content: space-between;

			
			.registration__text {
				font-family: "Mariupol";
				font-size: 16px;
				font-weight: 500;
				color: #808080;
				text-align: justify;
				margin-bottom: 40px;
			}
		}
	}
}

@media (max-width: 1300px) {
	.registration {
		.registration__content {
			max-width: 990px;
			.registration__img {
			}
			.registration__info {
				span {
				}
			}
			.registration__container {
				.registration__text {
					font-size: 12px;
				}
			}
		}
	}
}

@media (max-width: 990px) {
	.registration {
		// height: 80vh;
		.registration__content {
			max-width: 760px;
			.registration__img {
			}
			.registration__info {
				font-size: 12px;
				span {
				}
			}
			.registration__container {
				width: 80vw;
				.registration__text {
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.registration {
		padding: 0 10px;
		.registration__content {
			.registration__img {
			}
			.registration__info {
				span {
				}
			}
			.registration__container {
				max-width: 100%;
				.registration__text {
				}
			}
		}
	}
}
