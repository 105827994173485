.location {
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	.location__container {
		max-width: 1280px;
		margin: 0 auto;
	}
}

@media (max-width: 1300px) {
	.location {
		.location__container {
			width: 50vw;
			margin: 0 auto;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(2, 1fr);
		}
	}
}

@media (max-width: 990px) {
	.location {
		.location__container {
			width: 80vw;
		}
	}
}

@media (max-width: 768px) {
	.location {
		.location__container {
		}
	}
}
