.start {
	color: #fff;
	height: 100vh;
	background: url("../../assets/images/startBg.jpg") no-repeat;
	background-size: cover;
	background-position: center center;

	.start__content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding-top: 2.5%;
		margin: 0 auto;
		text-align: center;
		width: 50%;
		height: 40%;
		.start__logo {
			width: 40px;
			margin: 0 auto;
			margin-bottom: 45px;
		}
		.start__title {
			text-align: center;
			font-family: "1000";
			font-size: 54px;
			text-transform: uppercase;
			margin-bottom: 35px;
		}
		.start__subtitle {
			font-size: 16px;
			font-family: "Mariupol";
			font-weight: 500;
		}
	}
}

@media (max-width: 1300px) {
	.start {
		.start__content {
			height: 25%;
			width: 80%;
			.start__logo {
			}
			.start__title {
				font-size: 34px;
			}
			.start__subtitle {
			}
		}
	}
}

@media (max-width: 990px) {
	.start {
		.start__content {
			height: 40%;
			.start__logo {
			}
			.start__title {
			}
			.start__subtitle {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 768px) {
	.start {
		.start__content {
			.start__logo {
			}
			.start__title {
				font-size: 24px;
			}
			.start__subtitle {
			}
		}
	}
}
