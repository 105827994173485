.contactPerson {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	.contactPerson__img {
	}
	.contactPerson__name {
		font-family: "Mariupol";
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		color: #808080;
		margin: 40px 0px;
		.contactPerson__delim {
			color: #e3e3e3;
		}
		.contactPerson__position {
			color: #c8c8c8;
		}
	}
	.contactPerson__descr {
		color: #808080;
		text-align: justify;
		font-family: "Mariupol";
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 35px;
	}
	.contactPerson__links {
		// display: grid;
		// grid-template-columns: 4fr 1fr 1fr 1fr;
		// gap: 3%;
		display: flex;
		justify-content: space-between;
		.contactPerson__btnContainer {
			width: 68.571429%; /* 432/630 */
		}
		.contactPerson__link {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			background: #4da5c7;
			width: 42px;
			transition: background-color 0.5s ease;
			&:hover {
				background: #606060;
			}
			img {
				margin: 0 auto;
			}
		}
		.contactPerson__link-email img{
			width: 24px;
		}
	}
}

@media (max-width: 990px) {
	.contactPerson {
		.contactPerson__img {
		}
		.contactPerson__name {
			font-size: 12px;
			.contactPerson__delim {
			}
			.contactPerson__position {
			}
		}
		.contactPerson__descr {
			font-size: 12px;
		}
		.contactPerson__links {
			.contactPerson__btnContainer {
			}
			.contactPerson__link {
				&:hover {
				}
				img {
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.contactPerson {
		.contactPerson__img {
		}
		.contactPerson__name {
			.contactPerson__delim {
			}
			.contactPerson__position {
			}
		}
		.contactPerson__descr {
		}
		.contactPerson__links {
			.contactPerson__btnContainer {
				width: 60%;
			}
			.contactPerson__link {
				width: 34px;
				&:hover {
				}
				img {
				}
			}
		}
	}
}
